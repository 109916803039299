import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import {
  FooterSection,
  Title,
  Large,
  Chat,
  Empty,
  Language,
} from "./styles";

const changeTheme = () => {
  let theme = document.documentElement.getAttribute("data-selected-theme");
  if (theme === "dark") {
    document.documentElement.setAttribute("data-selected-theme", "default");}
  else document.documentElement.setAttribute("data-selected-theme", "dark");

}

const Footer = ({ t }: { t: TFunction }) => {




  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("CONTACT US")}</Language>
                {t(`Don’t hesitate to call or text!`)}
              <a href="tel:41680DRIVE(37483)">
                <Chat>{t(`416 80 DRIVE (37483)`)}</Chat>
              </a>
              <a href="mailto:80safedriving@gmail.com">
                <Chat>{t(`80safedriving@gmail.com`)}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("")}</Title>
              <Large to="/">{t("")}</Large>
              <Large to="/">{t("")}</Large>
              <button onClick={changeTheme}>CHANGE THEME</button>

              <Large to="/">{t("")}</Large>

            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Empty/>
              <SvgIcon
                  src="BAlogo.png"
                  aria-label="homepage"
                  width="55px"
                  height="55px"
              />            </Col>
          </Row>

        </Container>
      </FooterSection>

    </>
  );
};

export default withTranslation()(Footer);
